<template>
  <article class="detail-list">
    <header>
      <a-descriptions>
        <a-descriptions-item label="负责人">
          {{ $attrs.task?.head || "" }}
        </a-descriptions-item>
        <a-descriptions-item label="任务日期">
          {{ $attrs.task?.action_time || "" }}
        </a-descriptions-item>
        <a-descriptions-item label="结束日期">
          {{ $attrs.task?.action_time || "" }}
        </a-descriptions-item>
        <a-descriptions-item label="执行人">
          {{ $attrs.task?.executor || "" }}
        </a-descriptions-item>
        <a-descriptions-item label="执行日期">
          {{ $attrs.task?.check_time || "" }}
        </a-descriptions-item>
        <a-descriptions-item label="">
          {{ title }}
        </a-descriptions-item>
      </a-descriptions>
    </header>
    <a-divider />
    <section class="list">
      <div v-for="(item, index) in detailState.list || []" :key="item.id">
        <DetailItem :data="item"></DetailItem>
        <template
          v-for="(child, childIndex) in detailState.innerList[index]?.list ||
          []"
        >
          <DetailInnerItem
            :data="child"
            :key="`${index}-${childIndex}`"
          ></DetailInnerItem>
        </template>
      </div>
    </section>
  </article>
</template>
<script setup>
import { getDetail } from "../methods/getDetail";
import DetailInnerItem from "./detail-inner/detail-inner-item.vue";
import DetailItem from "./detail-item.vue";
const props = defineProps({
  templateNo: {
    type: String,
    default: "",
  },
  check_list: {
    type: Array,
    default: () => [],
  },
});
const emits = defineEmits(["close", "submit"]);
const { detailState, title } = getDetail(props, emits);
</script>
<style lang="less" scoped>
.detail-list {
  :deep(.list) {
    .detail-item,
    .detail-inner-item {
      border-bottom: none;
    }
    .detail-item {
      font-weight: bolder;
    }
    .detail-inner-item {
      // text-indent: 2em;
      margin-left: 2em;
      .title {
        span.name {
          font-size: 14px;
        }
      }
      .form {
        display: none;
      }
    }
  }
}
header {
  padding: 0 5px;

  :deep(.ant-descriptions-row) {
    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      font-size: 16px;
      font-weight: bolder;
      color: #1e1e1e;
    }
  }
}
</style>
