<template>
  <article class="class">
    <!--   <BasicForm @register="registerForm">
      <template v-slot:pjmb="{ model, field }">
        <div class="pjmb">
          <a-input value="日管控模版" disabled />
          <a-button type="link" @click="s(model[field])">
            <template #icon>
              <unordered-list-outlined />
            </template>

            查看明细</a-button
          >
        </div>
      </template>
    </BasicForm> -->
    <a-form
      :model="formData"
      name="basic"
      autocomplete="off"
      :layout="'vertical'"
    >
      <a-form-item
        label="评价模版"
        name="pjmb"
        :rules="[{ required: true, message: '请输入不符合原因!' }]"
      >
        <div class="pjmb">
          <a-input value="日管控模版" disabled />
          <a-button type="link" @click="s(formData['pjmd'])">
            查看明细</a-button
          >
        </div>
      </a-form-item>
      <div style="display: flex">
        <a-form-item
          label="负责人"
          name="head"
          :rules="[{ required: true, message: '请选择负责人!' }]"
        >
          <a-select
            v-model="formData.head"
            :disabled="link !== Links.Plan"
            style="width: 240px"
          >
            <a-select-option
              :value="val.value"
              v-for="val in options.user"
              :key="val.value"
            >
              {{ val.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          style="margin: 0 30px"
          label="任务日期"
          name="action_time"
          :rules="[{ required: true, message: '请选择任务日期!' }]"
        >
          <a-date-picker
            style="width: 240px"
            v-model="formData.action_time"
            :format="'YYYY-MM-DD'"
            :disabled="link !== Links.Plan"
          />
        </a-form-item>
        <a-form-item
          style="margin: 0 30px"
          label="结束日期"
          name="action_time"
          :rules="[{ required: true, message: '请选择结束日期!' }]"
        >
          <a-date-picker
            style="width: 240px"
            disabled
            v-model="formData.action_time"
            :format="'YYYY-MM-DD'"
          />
        </a-form-item>
      </div>
      <div style="display: flex">
        <a-form-item
          label="执行人"
          name="executor"
          :rules="[{ required: true, message: '请输入不符合原因!' }]"
        >
          <a-input
            style="width: 240px"
            v-model="formData.executor"
            :disabled="link !== Links.Plan"
          />
        </a-form-item>
        <a-form-item
          style="margin-left: 30px"
          label="执行日期"
          name="check_time"
          :rules="[{ required: true, message: '请选择执行日期!' }]"
        >
          <a-date-picker
            style="width: 240px"
            v-model="formData.check_time"
            :format="'YYYY-MM-DD'"
            :disabled="link !== Links.Plan"
          />
        </a-form-item>
      </div>
    </a-form>
  </article>
</template>
<script setup>
import { BasicForm } from "@/components/Form/index";
import { UnorderedListOutlined } from "@ant-design/icons-vue";
import { Links } from "../data/template";
import { getFormPlan } from "../methods/getFormPlan";

const props = defineProps({
  obj: {
    type: Object,
    default: () => ({}),
  },
});
const emits = defineEmits(["showView"]);
const { link, options, getFormPlanData, formData } = getFormPlan(props);
function s(val) {
  console.log(val);
  emits("showView", val);
}

defineExpose({ getFormPlanData });
</script>
<style lang="less" scoped>
.pjmb {
  display: flex;
  align-items: center;
}
</style>
