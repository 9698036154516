import { computed, provide, reactive, ref, watch } from "vue";
import { ItemStatus, Links } from "../data/template";
import {
  getMockTemplateData,
  getMockTemplateDataChild,
} from "../mock/template";
import { useGetLink } from "./getAddForm";
export function getDetail(props, emits) {
  const link = useGetLink();
  const detailState = reactive({
    list: [],
    innerList: [],
    index: 0,
  });
  const isAll = ref(true);
  if ([Links.Rectification, Links.Examine].includes(link.value)) {
    isAll.value = false;
  }
  const myList = computed(() => {
    if (isAll.value) return detailState.list;
    return detailState.list.filter((item) => {
      return item.status === 2;
    });
  });
  const getMyinnerList = (innerList) => {
    if (isAll.value) return innerList;
    return innerList.filter((item) => {
      return item.status === 2;
    });
  };
  const back = () => {
    if ([Links.Rectification, Links.Examine].includes(link.value)) {
      detailState.index = 0;
    } else {
      detailState.index -= 1;
    }
  };
  const close = () => {
    emits("close");
  };
  const next = () => {
    if (detailState.index === detailState.list.length) {
      detailState.index = 0;
    } else {
      detailState.index += 1;
    }
  };
  const select = (item, index) => {
    const i = detailState.list.findIndex((d) => d.itemNo === item.itemNo);
    detailState.index = i + 1;
  };
  const title = computed(() => {
    const { index, list } = detailState;
    if (index === 0) {
      if ([Links.Rectification].includes(link.value)) return "状态：待整改";
      if ([Links.Examine].includes(link.value)) return "状态：整改后待审核";
      if ([Links.End].includes(link.value)) return "状态：整改通过";
      return "";
    }
    const item = list[index - 1];
    if (item) return item.itemNo + "、" + item.itemName;
    return "";
  });
  const getList = async () => {
    try {
      const res = await getMockTemplateData(props.templateNo);
      if (props.check_list && props.check_list.length > 0) {
        /**平铺之前的数据结构 */
        const checks = {};
        props.check_list.forEach((element) => {
          const objKeys = Object.keys(element);
          objKeys.forEach((key) => {
            checks[key] = element[key];
          });
        });
        detailState.list = res.map((item) => {
          const children = checks[item.itemName];
          if (children) {
            //不符合的
            let bfh = 0;
            //已整改的
            let yzg = 0;
            /**找出子项是否有不符合并且的然后修改状态 */
            children.forEach((child) => {
              if (child.status === ItemStatus["不符合"]) {
                bfh += 1;
              }
              if (child.isZG) {
                yzg += 1;
              }
            });
            console.log(bfh, yzg);
            if (bfh > 0) {
              item.status = ItemStatus["不符合"];
              item.zg = bfh > yzg ? "待整改" : "已整改";
              item.zgcount = bfh;
            }
          }
          return item;
        });
        getChildList(checks);
      } else {
        detailState.list = res;
        getChildList();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getChildList = async (checks) => {
    try {
      for (let i = 0; i < detailState.list.length; i++) {
        const item = detailState.list[i];
        const res = await getMockTemplateDataChild(item.itemNo);
        const d = {
          key: item.itemNo,
          list: res.map((d) => {
            d.parentName = item.itemName;
            if (checks) {
              const childs = checks[item.itemName];
              if (childs) {
                const chi = childs.filter((ch) => ch.itemNo === d.itemNo);
                if (chi.length > 0) {
                  d.defaultFormData = chi[0];
                }
              }
            }
            return d;
          }),
        };
        detailState.innerList.push(d);
      }
    } catch (error) {
      console.log(error);
    }
  };
  watch(
    () => props.templateNo,
    () => {
      if (props.templateNo) getList();
    },
    {
      immediate: true,
    }
  );
  /**收集到的数据全放这里面 */
  const dataList = ref([]);
  const setDataList = (data, name) => {
    const arr = dataList.value.filter((item) => {
      return !!item[name];
    });
    if (arr && arr.length > 0) {
      arr[0][name].push(data);
    } else {
      dataList.value.push({
        [name]: [data],
      });
    }
  };
  provide("SetData", {
    setDataList,
  });
  function submit() {
    console.log(dataList.value);
    emits("submit", dataList.value);
  }
  return {
    detailState,
    back,
    next,
    close,
    select,
    title,
    link,
    myList,
    getMyinnerList,
    isAll,
    submit,
  };
}
