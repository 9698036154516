<template>
  <article class="detail-item">
    <span class="name"> {{ data.itemNo }}、{{ data.itemName }}</span>
    <span class="child">共{{ data.children }}项</span>
    <span
      v-if="[Links.Rectification, Links.Examine, Links.End].includes(link) && data.status === 2"
      class="amend"
      >不符合{{ data.zgcount }}项</span
    >
    <span
      v-if="[Links.Rectification, Links.Examine, Links.End].includes(link) && data.status === 2"
      class="amendCom"
      >{{ data.zg }}</span
    >
  </article>
</template>
<script  setup>
  import { ITemplateData, Links } from '../data/template';
  import { useGetLink } from '../methods/getAddForm';

  defineProps({
    data: {
      type: Object,
      default: () => ({}),
    },
  });
  const link = useGetLink();
</script>
<style lang="less" scoped>
  .detail-item {
    width: 100%;
    font-size: 14px;
    line-height: 30px;
    border-bottom: 1px dashed #dcdcdc;
    margin-bottom: 15px;
    padding-bottom: 10px;
    cursor: pointer;
    padding-left: 20px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .name {
    font-size: 16px;
  }
  .child {
    color: #409eff;
  }
  .amend {
    color: #f56c6c;
  }
  .amendCom {
    color: #67c23a;
  }
</style>
