<template>
  <Select
    @dropdown-visible-change="handleFetch"
    v-bind="$attrs"
    @change="handleChange"
    :options="getOptions"
    v-model="state"
  >
    <template #[item]="data" v-for="item in Object.keys($slots)">
      <slot :name="item" v-bind="data || {}"></slot>
    </template>
    <template #suffixIcon v-if="loading">
      <LoadingOutlined spin />
    </template>
    <template #notFoundContent v-if="loading">
      <span>
        <LoadingOutlined spin class="mr-1" />
        {{ t('component.form.apiSelectNotFound') }}
      </span>
    </template>
  </Select>
</template>
<script >
  import { defineComponent, ref, watchEffect, computed, unref, watch } from 'vue';
  import { Select } from 'ant-design-vue';
  import { isFunction } from '@/utils/is';
  import { useRuleFormItem } from '@/hooks/component/useFormItem';
  import { useAttrs } from '@/hooks/core/useAttrs';
  import { get, omit } from 'lodash-es';
  import { LoadingOutlined } from '@ant-design/icons-vue';
  import { useI18n } from '@/hooks/web/useI18n';
  import { propTypes } from '@/utils/propTypes';
  export default defineComponent({
    name: 'ApiSelect',
    components: {
      Select,
      LoadingOutlined,
    },
    inheritAttrs: false,
    props: {
      value: [Array, Object, String, Number],
      numberToString: propTypes.bool,
      api: {
        type: Function,
        default: null,
      },
      // api params
      params: {
        type: Object,
        default: () => ({}),
      },
      // support xxx.xxx.xx
      resultField: propTypes.string.def(''),
      labelField: propTypes.string.def('label'),
      valueField: propTypes.string.def('value'),
      immediate: propTypes.bool.def(true),
      alwaysLoad: propTypes.bool.def(false),
    },
    emits: ['options-change', 'change', 'update:value'],
    setup(props, { emit }) {
      const options = ref([]);
      const loading = ref(false);
      const isFirstLoad = ref(true);
      const emitData = ref([]);
      const attrs = useAttrs();
      const { t } = useI18n();
      // Embedded in the form, just use the hook binding to perform form verification
      const [state] = useRuleFormItem(props, 'value', 'change', emitData);
      const getOptions = computed(() => {
        const { labelField, valueField, numberToString } = props;
        return unref(options).reduce((prev, next) => {
          if (next) {
            const value = next[valueField];
            prev.push(Object.assign(Object.assign({}, omit(next, [labelField, valueField])), { label: next[labelField], value: numberToString ? `${value}` : value }));
          }
          return prev;
        }, []);
      });
      watchEffect(() => {
        props.immediate && !props.alwaysLoad && fetch();
      });
      watch(() => state.value, (v) => {
        emit('update:value', v);
      });
      watch(() => props.params, () => {
        !unref(isFirstLoad) && fetch();
      }, { deep: true });
      async function fetch() {
        const api = props.api;
        if (!api || !isFunction(api))
          return;
        options.value = [];
        try {
          loading.value = true;
          const res = await api(props.params);
          if (Array.isArray(res)) {
            options.value = res;
            emitChange();
            return;
          }
          if (props.resultField) {
            options.value = get(res, props.resultField) || [];
          }
          emitChange();
        }
        catch (error) {
          console.warn(error);
        }
        finally {
          loading.value = false;
        }
      }
      async function handleFetch(visible) {
        if (visible) {
          if (props.alwaysLoad) {
            await fetch();
          }
          else if (!props.immediate && unref(isFirstLoad)) {
            await fetch();
            isFirstLoad.value = false;
          }
        }
      }
      function emitChange() {
        emit('options-change', unref(getOptions));
      }
      function handleChange(_, ...args) {
        emitData.value = args;
      }
      return { state, attrs, getOptions, loading, t, handleFetch, handleChange };
    },
  });

</script>
