<template>
  <a-steps v-bind="$attrs" :items="items">
    <a-step v-for="item in items" :key="item.title">
      <template #title>{{ item.title }}</template>
    </a-step>
  </a-steps>
</template>
<script lang="ts" setup>
  const items = [
    {
      title: '计划',
    },
    {
      title: '检查',
    },
    {
      title: '整改',
    },
    // {
    //   title: '审核',
    // },
    {
      title: '结束',
    },
  ];
</script>
<style lang="less" scoped>
  .class {
  }
</style>
