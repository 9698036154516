import { computed, unref } from 'vue';
import { isNumber } from '@/utils/is';
export function useItemLabelWidth(schemaItemRef, propsRef) {
  return computed(() => {
    const schemaItem = unref(schemaItemRef);
    const { labelCol = {}, wrapperCol = {} } = schemaItem.itemProps || {};
    const { labelWidth, disabledLabelWidth } = schemaItem;
    const { labelWidth: globalLabelWidth, labelCol: globalLabelCol, wrapperCol: globWrapperCol, layout, } = unref(propsRef);
    // If labelWidth is set globally, all items setting
    if ((!globalLabelWidth && !labelWidth && !globalLabelCol) || disabledLabelWidth) {
      labelCol.style = {
        textAlign: 'left',
      };
      return { labelCol, wrapperCol };
    }
    let width = labelWidth || globalLabelWidth;
    const col = Object.assign(Object.assign({}, globalLabelCol), labelCol);
    const wrapCol = Object.assign(Object.assign({}, globWrapperCol), wrapperCol);
    if (width) {
      width = isNumber(width) ? `${width}px` : width;
    }
    return {
      labelCol: Object.assign({ style: { width } }, col),
      wrapperCol: Object.assign({ style: { width: layout === 'vertical' ? '100%' : `calc(100% - ${width})` } }, wrapCol),
    };
  });
}
