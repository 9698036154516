export const Links = {
  /**@description:计划 */
  Plan: "计划",
  /**检查 */
  Check: "检查",
  /**整改 */
  Rectification: "整改",
  /**审核 */
  Examine: "审核",
  /**结束 */
  End: "结束",
};
export const Status = {
  NewBuild: 1,
  InProgress: 2,
  Completed: 3,
  Audited: 4,
  ReviewFailed: 5,
};
export const ItemStatus = {
  符合: 1,
  不符合: 2,
  不适用: 3,
};
export const ItemStatusText = {
  1: "符合",
  2: "不符合",
  3: "不适用",
};
export const statusObj = {
  [Status.NewBuild]: "新建",
  [Status.InProgress]: "进行中",
  [Status.Completed]: "已完成",
  [Status.Audited]: "已审核",
  [Status.ReviewFailed]: "审核不通过",
};
export const CheckStatus = {
  /**@description:零报告 */
  ZeroReport: 1,
  /**@description:风险隐患(待整改) */
  RiskHazardsToBeRectified: 2,
  /**@description:风险隐患(已整改) */
  RiskHazardsAudit: 3,
};
export const checkStatusObj = {
  [CheckStatus.ZeroReport]: "零报告",
  [CheckStatus.RiskHazardsToBeRectified]: "风险隐患(待整改)",
  [CheckStatus.RiskHazardsAudit]: "风险隐患(已整改)",
};
export const links = [
  Links.Plan,
  Links.Check,
  Links.Rectification,
  Links.Examine,
  Links.End,
];

export const replaceCheckList = (checklist, formData, parentName) => {
  const result = [];
  checklist.forEach((check) => {
    if (check[parentName]) {
      check[parentName] = check[parentName].map((item) => {
        if (item.itemNo === formData.itemNo) return formData;
        return item;
      });
    }
    result.push(check);
  });
  return result;
};
