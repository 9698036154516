import { computed, ref, watch, set } from "vue";
import { ItemStatus, Links, replaceCheckList } from "../../data/template";
import { useGetId, useGetLink, useGetObj } from "../../methods/getAddForm";
import { inject } from "vue";
import { dailylyControlApi } from "@/api/control/dailyControl.api";
import { message } from "ant-design-vue";
import moment from "moment";

export default function (props) {
  const uploadUrl = process.env.VUE_APP_NEW_URL + "/comapi/UploadApi";
  const link = useGetLink();
  const id = useGetId();
  const changeTime = (val) => {
    console.log(val);
    if (moment(val).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      formData.value.immediate = true;
    } else {
      formData.value.immediate = false;
    }
  };
  const changeImmediate = (val) => {
    if (val) {
      formData.value.date = moment();
    }
  };

  const formData = ref({
    // 小标题
    title: props.data.itemName,
    //状态：符合/不符合/不适用 1/2/3
    status: -1,
    // 不符合内容，其余传空
    content: "",
    // 不符合上传的图片地址，其余传空
    img: "",
    // 整改期限 不符合传具体日期，其余传空
    date: null,
    // 是否立即整改 不符合传true/false，其余传空
    immediate: false,
    itemName: props.data.itemName,
    itemNo: props.data.itemNo,
  });

  if ( props.data.defaultFormData&&props.data.defaultFormData.isZG) {
    formData.value = props.data.defaultFormData;
  } else if (link.value === Links.Rectification) {
    const data = props.data.defaultFormData || formData.value
    formData.value = Object.assign({}, data, {
      /**产生原因 */
      cause: "",
      /**整改时间 */
      changeDate: "",
      /**防范措施 */
      measure: "",
      /**解决方法 */
      method: "",
      /**解决方法照片 */
      methodImg: "",
      /**是否整改 */
      isZG: false,
    });
  }

  const { setDataList } = inject("SetData");
  setDataList(formData.value, props.data.parentName);

  watch(
    () => link.value,
    () => {
      if (link.value === Links.Check) {
        /**默认符合 */
        formData.value.status = ItemStatus["符合"];
      }
      //   else if (
      //     [Links.Rectification, Links.Examine, Links.End].includes(link.value)
      //   ) {
      //     props.data.status === 2 ? (formData.isConform = "不符合") : null;
      //   }
      //   if ([Links.Examine, Links.End].includes(link.value)) {
      //     props.data.status === 2 ? (isZG.value = true) : null;
      //   }
    }
  ),
    {
      immediate: true,
    };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  function handleCancel() {
    previewVisible.value = false;
  }
  const previewVisible = ref(false);
  const previewImage = ref('');
  const fileList = ref([]);
  const fileList2 = ref([]);
  async function handlePreview (file) {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    previewImage.value = file.url || file.preview;
    previewVisible.value = true;
  }
  const options = computed(() => {
    return [
      {
        label: "符合",
        value: ItemStatus["符合"],
        disabled: link.value === Links.Plan,
      },
      {
        label: "不符合",
        value: ItemStatus["不符合"],
        disabled: link.value === Links.Plan,
      },
      {
        label: "不适用",
        value: ItemStatus["不适用"],
        disabled: link.value === Links.Plan,
      },
    ];
  });
  const handleChange = (info) => {
    console.log("info.file.status", info);

    if (info.file.status === "done") {
      // Get this url from response in real world.
      console.log(info);
      formData.value.img = info.file.response.data.file_url;
      fileList.value = info.fileList;
    }
    if (info.file.status === "error") {
      message.error("upload error");
    }
  };
  const handleChange2 = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      console.log(info);
      formData.value.methodImg = info.file.response.data.file_url;
      fileList2.value = info.fileList;
    }
    if (info.file.status === "error") {
      message.error("upload error");
    }
  };
  const getObj = useGetObj();
  async function submit() {
    try {
      const obj = getObj();
      console.log(obj);
      if (obj) {
        formData.value.isZG = true;
        const oldCheckList = obj.check_list;
        const newCheckList = replaceCheckList(
          oldCheckList,
          formData.value,
          props.data.parentName
        );
        console.log(newCheckList);
        /**替换checklist */
        const params = {
          id,
          check_list: newCheckList,
        };
        await dailylyControlApi.updateForm(params);
        message.success("提交成功");
      } else {
        return message.error("未找到原始数据");
      }
    } catch (error) {
      console.log(error);
      message.error("提交失败");
      formData.value.isZG = false;
    }
  }
  return {
    uploadUrl,
    previewVisible,
    link,
    id,
    changeTime,
    changeImmediate,
    formData,
    fileList,
    previewImage,
    fileList2,
    handlePreview,
    handleCancel,
    options,
    handleChange,
    handleChange2,
    submit,
  };
}
