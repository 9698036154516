<!--
 * @Description:It is troublesome to implement radio button group in the form. So it is extracted independently as a separate component
-->
<template>
  <RadioGroup v-bind="attrs" v-model="state" button-style="solid" @change="handleChange">
    <template v-for="item in getOptions" >
      <RadioButton v-if="props.isBtn" :key="item.value" :value="item.value" :disabled="item.disabled">
        {{ item.label }}
      </RadioButton>
      <Radio v-else :key="item.value" :value="item.value" :disabled="item.disabled">
        {{ item.label }}
      </Radio>
    </template>
  </RadioGroup>
</template>
<script>
  import { defineComponent, ref, watchEffect, computed, unref, watch } from 'vue';
  import { Radio } from 'ant-design-vue';
  import { isFunction } from '@/utils/is';
  import { useRuleFormItem } from '@/hooks/component/useFormItem';
  import { useAttrs } from '@/hooks/core/useAttrs';
  import { propTypes } from '@/utils/propTypes';
  import { get, omit } from 'lodash-es';
  import { useI18n } from '@/hooks/web/useI18n';
  export default defineComponent({
    name: 'ApiRadioGroup',
    components: {
      RadioGroup: Radio.Group,
      RadioButton: Radio.Button,
      Radio,
    },
    props: {
      api: {
        type: Function,
        default: null,
      },
      params: {
        type: [Object, String],
        default: () => ({}),
      },
      value: {
        type: [String, Number, Boolean],
      },
      isBtn: {
        type: [Boolean],
        default: false,
      },
      numberToString: propTypes.bool,
      resultField: propTypes.string.def(''),
      labelField: propTypes.string.def('label'),
      valueField: propTypes.string.def('value'),
      immediate: propTypes.bool.def(true),
    },
    emits: ['options-change', 'change'],
    setup(props, { emit }) {
      const options = ref([]);
      const loading = ref(false);
      const isFirstLoad = ref(true);
      const emitData = ref([]);
      const attrs = useAttrs();
      const { t } = useI18n();
      // Embedded in the form, just use the hook binding to perform form verification
      const [state] = useRuleFormItem(props);
      // Processing options value
      const getOptions = computed(() => {
        const { labelField, valueField, numberToString } = props;
        return unref(options).reduce((prev, next) => {
          if (next) {
            const value = next[valueField];
            prev.push(Object.assign({ label: next[labelField], value: numberToString ? `${value}` : value }, omit(next, [labelField, valueField])));
          }
          return prev;
        }, []);
      });
      watchEffect(() => {
        props.immediate && fetch();
      });
      watch(() => props.params, () => {
        !unref(isFirstLoad) && fetch();
      }, { deep: true });
      async function fetch() {
        const api = props.api;
        if (!api || !isFunction(api))
          return;
        options.value = [];
        try {
          loading.value = true;
          const res = await api(props.params);
          if (Array.isArray(res)) {
            options.value = res;
            emitChange();
            return;
          }
          if (props.resultField) {
            options.value = get(res, props.resultField) || [];
          }
          emitChange();
        }
        catch (error) {
          console.warn(error);
        }
        finally {
          loading.value = false;
        }
      }
      function emitChange() {
        emit('options-change', unref(getOptions));
      }
      function handleChange(_, ...args) {
        emitData.value = args;
      }
      return { state, getOptions, attrs, loading, t, handleChange, props };
    },
  });

</script>
