<template>
  <a-form class="form-box" :model="formData" name="basic" autocomplete="off">
    <section class="rows">
      <a-form-item label="执行人" name="time">
        <a-input disabled v-model="data['executor']" />
      </a-form-item>
      <a-form-item label="执行日期" name="time" disabled>
        <a-date-picker
          disabled
          :default-value="moment(data['check_time'])"
          placeholder="请选择"
          :format="'YYYY-MM-DD'"
        />
      </a-form-item>
    </section>
    <!-- <a-form-item label="检查结果" name="time">
      <a-radio-group v-model="formData['check']" :options="options" />
    </a-form-item> -->
    <a-form-item label="检查说明" name="Illustrate">
      <div class="flexwapper">
        <a-textarea
          class="textarea"
          placeholder="本次检查说明"
          :autoSize="{ minRows: 4, maxRows: 6 }"
          v-model="formData.Illustrate"
        />
        <a-upload
          class="upload"
          :action="uploadUrl"
          list-type="picture-card"
          accept=".doc,.docx,.pdf,.xls,.xlsx,.png,.jpg,.jpge"
          @change="handleChange"
          :maxCount="1"
        >
            <div style="margin-top: 8px">点击上传</div>
        </a-upload>
      </div>
    </a-form-item>
    <a-form-item label="安全总监意见" name="advice">
      <a-textarea
        class="textarea"
        disabled
        placeholder="提示：只有食品安全总监才能填写此意见"
        :autoSize="{ minRows: 4, maxRows: 6 }"
        v-model="formData.advice"
      />
    </a-form-item>
    <a-form-item label="安全员签字" name="signPath">
      <a-input v-model="formData.sign" />
    </a-form-item>
  </a-form>
</template>
<script  setup>
  import moment from 'moment';
  import { computed, reactive } from 'vue';
  import { UploadChangeParam, message } from 'ant-design-vue';
  const uploadUrl = process.env.VUE_APP_NEW_URL + '/comapi/UploadApi';
  defineProps({
    data: {
      type: Object,
    },
  });

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      console.log(info);
      formData.illustrate_img = info.file.response.data.file_url;
    }
    if (info.file.status === 'error') {
      message.error('upload error');
    }
  };
  const options = [
    {
      label: '零报告',
      value: '零报告',
    },
    {
      label: '风险隐患',
      value: '风险隐患',
    },
  ];
  console.log('组件加载')
  const formData = reactive({
    signPath:
      'http://jsscjg.yishutec.com/ProductFileServer/company-self-sign/SPSC1970/986012b3889f46d4bcb0357dc1755e01/001.png?r=0.875839547052552',
    Illustrate: '',
    advice: '',
    illustrate_img: '',
    sign: '',
  });
  defineExpose({
    getFormData: () => formData,
  });
</script>
<style lang="less" scoped>
  .form-box {
    padding: 10px 18px;
  }
  .flexwapper {
    display: flex;
    align-items: center;
    gap: 20px;
    .textarea {
      width: 70%;
    }
    .upload {
      flex: 1;
    }
  }
  .item-row {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  .rows {
    display: flex;
    width: 100%;
    gap: 20px;
    > * {
      flex: 1;
    }
  }
</style>
