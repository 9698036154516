import { computed, nextTick, onMounted, reactive, ref, watch } from "vue";
import { useGetLink } from "./getAddForm";
import { useForm } from "@/components/Form";
import { Links } from "../data/template";
import { dailylyControlApi } from "@/api/control/dailyControl.api";
import dayjs from "dayjs";
import moment from "moment";
export function getFormPlan(props) {
  const format = "YYYY-MM-DD";
  const link = useGetLink();
  const options = reactive({
    user: [],
    template: [
      {
        label: "日管控模板",
        value: "42852754ba064ff58d6425d33fa599aa"
      }
    ]
  });
  console.log("link.value", link.value);
  const formSchema = computed(() => {
    const result = [
      {
        field: "pjmb",
        label: "评价模版",
        colProps: {
          span: 24
        },
        component: "Input",
        slot: "pjmb"
      },
      {
        label: "负责人",
        field: "head",

        component: "Select",
        colProps: {
          span: 8
        },
        componentProps: {
          disabled: link.value !== Links.Plan,
          options: options.user,
          required: true,
          rules: [{ required: true, message: "Please select your gender!" }]
        }
      },
      {
        label: "任务日期",
        field: "action_time",
        required: true,
        component: "DatePicker",
        colProps: {
          span: 8
        },
        componentProps: {
          disabled: link.value !== Links.Plan
        }
      },
      {
        label: "结束日期",
        field: "action_time",//"end_time",
        required: true,
        component: "DatePicker",
        colProps: {
          span: 8
        },
        componentProps: {
          disabled: true
        }
      },
      {
        label: "执行人",
        field: "executor",
        component: "Input",
        colProps: {
          span: 8
        },
        componentProps: {
          disabled: link.value !== Links.Plan
        }
      },
      {
        label: "执行日期",
        field: "check_time",
        component: "DatePicker",
        colProps: {
          span: 8
        },
        componentProps: {
          format,
          disabled: link.value !== Links.Plan
        }
      }
    ];
    if ([Links.Rectification, Links.Examine, Links.End].includes(link.value)) {
      result.push({
        label: "检查结果",
        field: "jcjg",
        component: "RadioGroup",
        componentProps: {
          disabled: link.value !== Links.Plan,
          options: [
            {
              label: "零报告",
              value: "零报告"
            },
            {
              label: "风险隐患",
              value: "风险隐患"
            }
          ]
        }
      });
      result.push({
        label: "检查说明",
        field: "jcsm",
        component: "InputTextArea",
        componentProps: {
          disabled: link.value !== Links.Plan
        }
      });
    }
    if ([Links.Examine, Links.End].includes(link.value)) {
      result.push({
        label: "开始整改",
        field: "kszg",
        component: "DatePicker",
        colProps: {
          span: 8
        },
        componentProps: {
          disabled: link.value !== Links.Plan
        }
      });
      result.push({
        label: "整改完成",
        field: "zgwc",
        component: "DatePicker",
        colProps: {
          span: 8
        },
        componentProps: {
          disabled: link.value !== Links.Plan
        }
      });
      result.push({
        label: "整改结果",
        field: "zgjg",
        component: "Input",
        colProps: {
          span: 8
        },
        render() {
          return "整改后待审核";
        }
      });
      result.push({
        label: "整改意见",
        field: "zgyh",
        component: "InputTextArea",
        componentProps: {
          disabled: link.value !== Links.Plan
        }
      });
    }
    return result;
  });
  // const [registerForm, { setFieldsValue, validate, resetSchema }] = useForm({
  //   labelWidth: 90,
  //   baseColProps: { span: 24 },
  //   schemas: formSchema.value,
  //   showActionButtonGroup: false
  // });
  // watch(
  //   () => formSchema.value,
  //   () => {
  //     resetSchema(formSchema.value);
  //   },
  //   {
  //     deep: true
  //   }
  // );
  async function init() {
    const userData = await dailylyControlApi.getUser({
      page: 1,
      pageSize: 100000
    });
    options.user = userData.results.map(item => ({
      label: item.name,
      value: item.id
    }));
    formData.value = {
      // state: 3,
      pjmb: "42852754ba064ff58d6425d33fa599aa",
      head: options.user[0] ? options.user[0].value : "",
      executor: "",
      action_time: moment(),
      end_time: moment(),
      check_time:moment(),
      jcjg: "风险隐患",
      jcsm: "说明说明"
    };
  }
  if (!props.obj.id) {
    init();
  }

  async function getFormPlanData() {
    // console.log("formData", formData);
    formData.value.check_time = dayjs(formData.value.check_time).format(format);
    formData.value.action_time = dayjs(formData.value.action_time).format(
      format
    );
    formData.value.end_time = dayjs(formData.value.action_time).format(format);
    return formData.value;
  }
  const formData = ref({
    ...props.obj,
  });
  return {
    link,
    options,
    // registerForm,
    getFormPlanData,
    formData
  };
}
