<template>
  <article class="detail-list">
    <header>
      <div class="title">{{ title }}</div>
      <a-space>
        <a-button v-if="link === Links.Check" type="success" @click="submit"
          >提交</a-button
        >
        <a-button
          v-if="detailState.index === 0"
          type="primary"
          @click="close"
          icon="arrow-left"
        >
          任务信息</a-button
        >
        <a-button
          v-if="detailState.index !== 0"
          type="primary"
          @click="back"
          icon="arrow-left"
        >
          返回</a-button
        >
        <a-button
          v-if="link === Links.Check || link === Links.Plan"
          type="primary"
          @click="next"
        >
          下一项<arrow-right-outlined
        /></a-button>
        <a-button
          v-if="
            [Links.Rectification, Links.Examine, Links.End].includes(link) &&
            isAll
          "
          @click="isAll = false"
          >不符合项</a-button
        >
        <a-button
          v-if="
            [Links.Rectification, Links.Examine, Links.End].includes(link) &&
            !isAll
          "
          @click="isAll = true"
          >查看全部</a-button
        >
      </a-space>
    </header>
    <a-divider />
    <section class="list" v-show="detailState.index === 0">
      <div
        v-for="(item, index) in myList"
        :key="item.id"
        @click="select(item, index)"
      >
        <DetailItem :data="item"></DetailItem>
      </div>
    </section>
    <section class="inner-list" v-show="detailState.index > 0">
      <template v-for="(list, index) in detailState.innerList">
        <DetailInnerItem
          v-show="detailState.index == index + 1"
          v-for="item in list.list"
          :key="`${list.key}-${item.id}`"
          :data="item"
        ></DetailInnerItem>
      </template>
    </section>
  </article>
</template>
<script setup>
import { Links } from "../data/template";
import { getDetail } from "../methods/getDetail";
import DetailInnerItem from "./detail-inner/detail-inner-item.vue";
import DetailItem from "./detail-item.vue";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons-vue";
const props = defineProps({
  templateNo: {
    type: String,
    default: "",
  },
  check_list: {
    type: Array,
    default: () => [],
  },
});
const emits = defineEmits(["close", "submit"]);
const {
  detailState,
  back,
  next,
  close,
  title,
  select,
  link,
  myList,
  isAll,
  submit,
} = getDetail(props, emits);
</script>
<style lang="less" scoped>
.detail-list {
}
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  .title {
    font-size: 16px;
    color: #1e1e1e;
    font-weight: 700;
  }
}
</style>
