<template>
  <a-modal v-model="myShow" @cancel="allClose" width="1000px" :title="title">
    <section class="form-box">
      <FormStep :current="current"></FormStep>
      <section class="content">
        <FormPlan
          ref="formPlan"
          :obj="obj"
          v-show="showFormPlan"
          @showView="hshowView"
        ></FormPlan>
        <DetailList
          v-show="showView"
          :template-no="viewCode || '42852754ba064ff58d6425d33fa599aa'"
          @close="() => (showView = false)"
          @submit="onSubmit"
          :check_list="obj?.check_list || []"
        ></DetailList>
      </section>

      <div class="daily-print-container">
        <div id="dailyPrint">
          <PrintDetailList
            :task="obj"
            :template-no="viewCode || '42852754ba064ff58d6425d33fa599aa'"
            :check_list="obj?.check_list || []"
          />
        </div>
      </div>
    </section>
    <template #footer>
      <a-button
        v-if="myLink === Links.Plan"
        key="submit"
        type="primary"
        @click="nextCheck"
        >执行</a-button
      >
      <a-button v-if="!!id" key="print" type="primary" v-print="printOptions"
        >打印</a-button
      >
      <a-button key="close" @click="allClose">关闭</a-button>
    </template>
    <a-modal
      v-model="formCheckState.show"
      width="800px"
      title="每日食品安全检查记录"
      @ok="jlSubmit"
    >
      <FormCheck ref="formCheck" :data="planFormData"></FormCheck>
      <template #footer>
        <LazyButton type="primary" @click="jlSubmit">确定</LazyButton>
      </template>
    </a-modal>
  </a-modal>
</template>
<script setup>
import { ref, computed } from "vue";
import FormStep from "./form-step.vue";
import FormPlan from "./form-plan.vue";
import DetailList from "./detail-list.vue";
import PrintDetailList from "./print-detail-list.vue";
import { Links } from "../data/template";
import { getAddForm } from "../methods/getAddForm";
import FormCheck from "./form-check.vue";
import { message } from "ant-design-vue";
import { dailyControlAdd } from "@/api/control";
import { LazyButton } from "@/components/LazyButton";

const props = defineProps({
  id: {
    default: "",
  },
  link: {
    type: String,
    default: Links.Plan,
  },
  show: {
    type: Boolean,
    default: false,
  },
  obj: {
    type: Object,
    default: () => ({}),
  },
});
const printOptions = {
  id: "dailyPrint",
  popTitle: "正常打印",
  extraHead: "",
};

const formCheck = ref();
const emits = defineEmits(["success", "update:show"]);

const myShow = computed({
  get() {
    return props.show;
  },
  set(val) {
    emits("update:show", val);
  },
});

const allClose = () => {
  formCheckState.show = false;
  myShow.value = false;
  if (myLink.value === Links.Rectification) {
    emits("success");
  }
};

const {
  title,
  showFormPlan,
  showView,
  viewCode,
  hshowView,
  current,
  nextCheck,
  myLink,
  formPlan,
  planFormData,
  onSubmit,
  formCheckState,
} = getAddForm(props);

// console.log(props);
/**真正提交 */
async function jlSubmit() {
  const checkForm = formCheck.value.getFormData();
  const obj = {
    ...checkForm,
    ...planFormData.value,
  };
  await dailyControlAdd(obj);
  message.success("提交成功"), message.success("本次检查已完成");
  formCheckState.show = false;
  myShow.value = false;
  emits("success");
}
</script>
<style lang="less" scoped>
.form-box {
  height: 600px;
  padding: 16px;
}
.content {
  margin-top: 20px;
  height: 90%;
  overflow: auto;
}

.daily-print-container {
  position: fixed;
  top: 0;
  background: #fff;
  z-index: -9999;
  width: 0;
  height: 0;
  // width: 100%;
  // height: 100vh;
  // overflow: auto;
}

@page {
  // size: auto;
  // margin: 3mm;
}
</style>
