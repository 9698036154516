var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{staticClass:"detail-list"},[_c('header',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_setup.title))]),_c('a-space',[(_setup.link === _setup.Links.Check)?_c('a-button',{attrs:{"type":"success"},on:{"click":_setup.submit}},[_vm._v("提交")]):_vm._e(),(_setup.detailState.index === 0)?_c('a-button',{attrs:{"type":"primary","icon":"arrow-left"},on:{"click":_setup.close}},[_vm._v(" 任务信息")]):_vm._e(),(_setup.detailState.index !== 0)?_c('a-button',{attrs:{"type":"primary","icon":"arrow-left"},on:{"click":_setup.back}},[_vm._v(" 返回")]):_vm._e(),(_setup.link === _setup.Links.Check || _setup.link === _setup.Links.Plan)?_c('a-button',{attrs:{"type":"primary"},on:{"click":_setup.next}},[_vm._v(" 下一项"),_c(_setup.ArrowRightOutlined)],1):_vm._e(),(
          [_setup.Links.Rectification, _setup.Links.Examine, _setup.Links.End].includes(_setup.link) &&
          _setup.isAll
        )?_c('a-button',{on:{"click":function($event){_setup.isAll = false}}},[_vm._v("不符合项")]):_vm._e(),(
          [_setup.Links.Rectification, _setup.Links.Examine, _setup.Links.End].includes(_setup.link) &&
          !_setup.isAll
        )?_c('a-button',{on:{"click":function($event){_setup.isAll = true}}},[_vm._v("查看全部")]):_vm._e()],1)],1),_c('a-divider'),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_setup.detailState.index === 0),expression:"detailState.index === 0"}],staticClass:"list"},_vm._l((_setup.myList),function(item,index){return _c('div',{key:item.id,on:{"click":function($event){return _setup.select(item, index)}}},[_c(_setup.DetailItem,{attrs:{"data":item}})],1)}),0),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_setup.detailState.index > 0),expression:"detailState.index > 0"}],staticClass:"inner-list"},[_vm._l((_setup.detailState.innerList),function(list,index){return _vm._l((list.list),function(item){return _c(_setup.DetailInnerItem,{directives:[{name:"show",rawName:"v-show",value:(_setup.detailState.index == index + 1),expression:"detailState.index == index + 1"}],key:`${list.key}-${item.id}`,attrs:{"data":item}})})})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }