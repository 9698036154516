import { unref } from "vue"
import {cloneDeep} from "lodash-es";
import { isObject } from '@/utils/is';

// 深度合并
export function deepMerge(src = {}, target = {}) {
  let key;
  const res = cloneDeep(src);
  for (key in target) {
    res[key] = isObject(res[key]) ? deepMerge(res[key], target[key]) : (res[key] = target[key]);
  }
  return res;
}

// dynamic use hook props
export function getDynamicProps(props) {
  const ret = {};

  Object.keys(props).map((key) => {
    ret[key] = unref(props[key]);
  });

  return ret
}
