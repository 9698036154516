<template>
  <div>
    <a-card title="日管控">
      <a-row :gutter="16">
        <a-col :span="24" style="max-width: 200px; margin-bottom: 12px">
          <a-input
            v-model="searchForm.search"
            placeholder="请输入关键词"
            allowClear
            @pressEnter="search"
          />
        </a-col>
        <a-col :span="24" style="width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button
            type="primary"
            icon="plus"
            style="margin: 0 8px"
            @click="openFormModal(form)"
            >新增</a-button
          >
        </div>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          size="small"
          :columns="columns"
          :pagination="pagination"
          :dataSource="items"
          rowKey="id"
          :loading="loading"
          @change="tableChange"
        >
          <div slot="total_item" slot-scope="value">
            <span
              >{{ value && value.not_conform }} /
              {{ value && value.total2 }}</span
            >
          </div>
          <div slot="state" slot-scope="value">
            <span>{{
              value === 1
                ? "新建"
                : value === 2
                ? "进行中"
                : value === 3
                ? "已完成"
                : value === 4
                ? "已审核"
                : "审核不通过"
            }}</span>
          </div>
          <div slot="check_result" slot-scope="value">
            <span>{{
              value === 1
                ? "零报告"
                : value === 2
                ? "风险隐患(待整改)"
                : value === 3
                ? "风险隐患(已整改)"
                : ''
            }}</span>
          </div>
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button
                v-if="
                  item.check_result === CheckStatus['RiskHazardsToBeRectified']
                "
                icon="edit"
                size="small"
                @click="openFormModal(item, Links.Rectification)"
                >整改</a-button
              >
              <a-button
                v-else
                icon="edit"
                size="small"
                @click="openFormModal(item, Links.End)"
                >查看</a-button
              >
              <a-popconfirm title="确定删除吗" @confirm="destroy(item.id)">
                <a-button type="danger" icon="delete" size="small"
                  >删除</a-button
                >
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
    <!-- <form-modal
            v-model="visible"
            :form="targetItem"
            :clientsClassificationOptions="clientsClassificationItems"
            @create="create"
            @update="update"
    /> -->
    <a-modal
      v-model="importLoading"
      :footer="null"
      :maskClosable="false"
      :closable="false"
    >
      <div><a-spin style="margin-right: 12px" />正在导入中, 请等待...</div>
    </a-modal>
    <AddForm
      v-if="modelState.show"
      v-bind="modelState"
      :show.sync="modelState.show"
      :link="modelState.link"
      :obj="modelState.obj"
      @success="handleSuccess"
    />
  </div>
</template>

<script>
import { CheckStatus, Links } from "./data/template.js";
import AddForm from "./component/add-form.vue";
import { dailyControlList, dailyControlDel } from "@/api/control";

export default {
  components: {
    AddForm,
    // FormModal: () => import("./FormModal.vue"),
  },
  data() {
    return {
      modelState: {
        link: Links.Plan,
        id: "",
        show: false,
        obj: {},

      },
      CheckStatus,
      Links,
      pagination: { current: 1, total: 0, pageSize: 10 },
      columns: [
        {
          title: "任务日期",
          dataIndex: "action_time",
        },
        {
          title: "执行日期",
          dataIndex: "check_time",
        },
        {
          title: "执行人",
          dataIndex: "executor",
        },
        {
          title: "负责人",
          dataIndex: "head",
        },
        {
          title: "完成日期",
          dataIndex: "end_time",
        },
        {
          title: "不符合/总项",
          scopedSlots: { customRender: "total_item" },
        },
        {
          title: "检查状态",
          scopedSlots: { customRender: "state" },
          dataIndex: "state",
        },
        {
          title: "检查结果",
          scopedSlots: { customRender: "check_result" },
          dataIndex: "check_result",
        },
        {
          title: "按时完成",
          dataIndex: "finish",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          width: "156px",
        },
      ],
      searchForm: { search: "", page: 1, page_size: 10 },
      loading: false,
      items: [],
      clientsClassificationItems: [],
      visible: false,
      targetItem: { others: {} },
      form: { others: {} },
      importLoading: false,
    };
  },
  computed: {},
  methods: {
    handleSuccess() {
      this.list();
    },
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      dailyControlList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create() {
      this.list();
    },
    update() {
      this.list();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    openFormModal(item, link) {
      console.log("openFormModalL:", item);
      this.modelState.link = link ? link : Links.Plan;
      this.modelState.id = item.id;
      this.modelState.obj = item;
      this.$set(this.modelState, "obj", item);
      this.modelState.show = true;
      this.$forceUpdate();
      // console.log(this.modelState);
      // this.targetItem = { ...item }
      // this.visible = true;
    },
    destroy(id) {
      dailyControlDel({ id: id })
        .then((data) => {
          this.$message.success("删除成功");
          this.list();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${
        sorter.field
      }`;
      this.list();
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
