<template>
  <article class="detail-inner-item">
    <div class="title">
      <a-icon
        v-if="data.star === 'hig'"
        style="color: red"
        type="star"
        theme="filled"
      />
      <span class="name">{{ data.itemNo }}、{{ data.itemName }}</span>
      <span
        v-if="[Links.Rectification, Links.Examine, Links.End].includes(link)"
        class="status"
        >（{{
          formData.status ? ItemStatusText[formData.status] : "符合"
        }}）</span
      >
      <span v-if="formData.isZG">已整改</span>
    </div>
    <div class="options" v-if="[Links.Check, Links.Plan].includes(link)">
      <a-radio-group v-model="formData.status" :options="options" />
      <!-- <a-checkbox v-if="value === '不符合'" :checked.sync="formState.isproblem"
        >重大问题</a-checkbox
      > -->
    </div>
    <div v-if="formData.status === ItemStatus['不符合']" class="form">
      <a-form
        :model="formData"
        name="basic"
        autocomplete="off"
        :layout="'vertical'"
      >
        <a-form-item
          label="不符合原因"
          name="content"
          :rules="[{ required: true, message: '请输入不符合原因!' }]"
        >
          <div class="flexwapper">
            <a-textarea
              class="textarea"
              placeholder="可输入500字以内的检查备注"
              :autoSize="{ minRows: 4, maxRows: 6 }"
              :disabled="
                [Links.Rectification, Links.Examine, Links.End].includes(link)
              "
              v-model="formData.content"
            />
            <a-upload
              v-if="
                ![Links.Rectification, Links.Examine, Links.End].includes(link)
              "
              class="upload"
              :action="uploadUrl"
              list-type="picture-card"
              accept=".doc,.docx,.pdf,.xls,.xlsx,.png,.jpg,.jpge"
              @change="handleChange"
              :maxCount="1"
            >
              <div v-if="fileList.length < 8">
                <div style="margin-top: 8px">点击上传</div>
              </div>
            </a-upload>
            <div class="imgbox" v-else-if="formData.img">
              <img  :src="formData.img" />
            </div>
          </div>
        </a-form-item>
        <section class="rows">
          <a-form-item label="整改期限" name="data">
            <a-space>
              <a-date-picker
                v-model="formData.date"
                :format="'YYYY-MM-DD'"
                @change="changeTime"
                :disabled="
                  [Links.Rectification, Links.Examine, Links.End].includes(link)
                "
              />
              <div
                v-if="
                  [Links.Rectification, Links.Examine, Links.End].includes(link)
                "
              >
                {{ formData.immediate ? "立即整改" : "" }}
              </div>
              <a-checkbox
                v-else
                v-model="formData.immediate"
                @change="changeImmediate"
                >立即整改</a-checkbox
              >
            </a-space>
          </a-form-item>
          <a-form-item
            v-if="
              [Links.Rectification, Links.Examine, Links.End].includes(link)
            "
            label="整改时间"
            name="changeDate"
            labelAlign="left"
            :rules="[{ required: true, message: '请选择整改时间！' }]"
          >
            <a-date-picker
              :disabled="formData.isZG"
              show-time
              placeholder="请选择"
              v-model="formData.changeDate"
              format="YYYY-MM-DD HH:mm:ss"
            />
          </a-form-item>
        </section>
        <!--   <a-form-item name="remember" :wrapper-col="{ offset: 8, span: 16 }">
          <a-checkbox :checked.sync="formState.remember">Remember me</a-checkbox>
        </a-form-item> -->
        <template
          v-if="[Links.Rectification, Links.Examine, Links.End].includes(link)"
        >
          <section class="rows">
            <a-form-item label="产生原因" name="cause">
              <a-textarea
                :disabled="formData.isZG"
                class="textarea"
                placeholder="不符合项目产生原因"
                :autoSize="{ minRows: 4, maxRows: 6 }"
                v-model="formData.cause"
              />
            </a-form-item>
            <a-form-item label="针对不符合项目的防范措施" name="measure">
              <a-textarea
                :disabled="formData.isZG"
                class="textarea"
                placeholder="可输入200字以内"
                :autoSize="{ minRows: 4, maxRows: 6 }"
                v-model="formData.measure"
              />
            </a-form-item>
          </section>
          <a-form-item
            label="解决方法"
            name="method"
            :rules="[{ required: true, message: '请输入不符合原因!' }]"
          >
            <div class="flexwapper">
              <a-textarea
                :disabled="formData.isZG"
                class="textarea"
                placeholder="可输入200字以内"
                :autoSize="{ minRows: 4, maxRows: 6 }"
                v-model="formData.method"
              />
              <a-upload
                v-if="!formData.isZG"
                class="upload"
                :action="uploadUrl"
                list-type="picture-card"
                accept=".doc,.docx,.pdf,.xls,.xlsx,.png,.jpg,.jpge"
                @preview="handlePreview"
                @change="handleChange2"
                :maxCount="1"
              >
                <div v-if="fileList.length < 8">
                  <div style="margin-top: 8px">点击上传</div>
                </div>
              </a-upload>
              <div class="imgbox" v-else-if="formData.methodImg">
                <img  :src="formData.methodImg" />
              </div>
            </div>
          </a-form-item>

          <a-button
            v-if="!formData.isZG"
            :disabled="!formData.method"
            style="margin-left: auto; display: block; margin-right: 20px"
            type="primary"
            @click="submit"
            >提交整改项</a-button
          >
        </template>
        <!-- <template v-if="[Links.End].includes(link)">
          <section class="rows">
            <a-form-item label="审核结果" name="cause">
              <a-input
                disabled
                placeholder="整改完成"
                v-model="formData.cause"
              />
            </a-form-item>
            <a-form-item label="审核时间" name="time">
              <a-date-picker
                disabled
                show-time
                placeholder="请选择"
                :format="'YYYY-MM-DD HH:mm:ss'"
              />
            </a-form-item>
          </section>
          <a-form-item label="审核意见" name="cause">
            <a-input
              disabled
              placeholder="请输入审核意见"
              v-model="formData.cause"
            />
          </a-form-item>
        </template> -->
      </a-form>
      <a-modal v-model="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </div>
  </article>
</template>
<script setup>
import methods from "./methods";
import { Links, ItemStatus, ItemStatusText } from "../../data/template";
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
});
const {
  uploadUrl,
  link,
  id,
  changeTime,
  changeImmediate,
  formData,
  fileList,
  fileList2,
  previewVisible,
  previewImage,
  handleCancel,
  handlePreview,
  options,
  handleChange,
  handleChange2,
  submit,
} = methods(props);
</script>
<style lang="less" scoped>
.detail-inner-item {
  line-height: 30px;
  border-bottom: 1px dashed #dcdcdc;
  margin-bottom: 15px;
  padding-bottom: 10px;
  cursor: pointer;
  padding-left: 20px;
  position: relative;
}
.title {
  .name {
    font-size: 16px;
  }
}
.flexwapper {
  display: flex;
  align-items: center;
  gap: 20px;
  .textarea {
    width: 70%;
  }
  .upload {
    flex: 1;
  }
}
.item-row {
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.rows {
  display: flex;
  width: 100%;
  gap: 20px;
  > * {
    flex: 1;
  }
}
.status {
  color: rgb(64, 158, 255);
}
.ant-form-item {
  margin-bottom: 12px;
}
.imgbox{
  height: 100px;
  max-width: 200px;
  img{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
</style>
