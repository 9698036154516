import dayjs from 'dayjs';
import request from '@/utils/request';
import request1 from '@/utils/request1';
import { cloneDeep } from 'lodash-es';
const Api = {
  getList: '/daily_control/QDailyControlApi22',
  submitForm: '/daily_control/ADailyControlApi22',
  deleteForm: '/daily_control/DDailyControlApi22',
  getRiskTrackingList: '/company-check/company-check-amend/selectList',
  getUser: '/users/',
  updateForm: '/daily_control/UDailyControlApi22',
};

// const ApiWithBaseUrl = {}
// for (let key in Api) {
//   ApiWithBaseUrl[key] = process.env.VUE_APP_NEW_URL + Api[key]
// }

export const submitFormApi = (data) => request1.post({ url: Api.submitForm, data });
export const deleteFormApi = (data) => request.post({ url: Api.deleteForm, data });
export const getUser = (data) => request.get({ url: Api.getUser, data });
export const updateForm = (data) => request1.post({ url: Api.updateForm, data });
export const StatusEnum = {
  待整改: 10,
  待审核: 40,
  已整改: 50,
};
const getListApi = (params) => {
  const data = cloneDeep(params);
  if (data.check_data)
    data.check_data = dayjs(data.check_data).format('YYYY-MM-DD');
  return request.post({ url: Api.getList, data });
};
export const dailylyControlApi = {
  getList: getListApi,
  submitFormApi,
  getUser,
  updateForm
};
