import { ref, computed, provide, inject, reactive } from 'vue';
import { Links, links } from '../data/template';
import { message } from 'ant-design-vue';
import { cloneDeep } from 'lodash-es';
const getLinkS = Symbol();
export const useGetLink = () => {
  const res = inject(getLinkS);
  if (!res)
    throw Error('inject(getLinkS)出错');
  return res.links;
};
export const useGetId = () => {
  const res = inject(getLinkS);
  if (!res)
    throw Error('inject(getLinkS)出错');
  return res.id;
};
/**获取表单数据 */
export const useGetObj = () => {
  const res = inject(getLinkS);
  if (!res)
    throw Error('inject(getLinkS)出错');
  return ()=>res.obj;
};
export function getAddForm(props) {
  const formPlan = ref();
  const viewCode = ref('');
  const myLink = ref(props.link);
  const current = computed(() => {
    return links.indexOf(myLink.value);
  });
  const title = ref('检查任务维护');
  const showView = ref(false);
  if (myLink.value === Links.Rectification ||
      myLink.value === Links.Examine ||
      myLink.value === Links.Check) {
    showView.value = true;
  }
  const formCheckState = reactive({
    show: false,
  });
  const showFormPlan = computed(() => {
    if (!showView.value)
      return true;
    return false;
  });
  async function onSubmit(val) {
    planFormData.value.check_list = cloneDeep(val);
    planFormData.value.state = 3;
    message.success('提交成功');
    formCheckState.show = true;
  }
  provide(getLinkS, {
    links: computed(() => myLink.value),
    id: props.id,
    obj:props.obj
  });
  function hshowView(code) {
    console.log('触发触发')
    showView.value = true;
    viewCode.value = code;
  }
  const planFormData = ref();
  async function nextCheck() {
    console.log(formPlan.value);
    if (formPlan.value) {
      const form = await formPlan.value.getFormPlanData();
      planFormData.value = form;
      showView.value = true;
      viewCode.value = '42852754ba064ff58d6425d33fa599aa';
      myLink.value = Links.Check;
    }
  }
  return {
    title,
    showFormPlan,
    showView,
    viewCode,
    hshowView,
    current,
    nextCheck,
    myLink,
    formPlan,
    planFormData,
    onSubmit,
    formCheckState,
  };
}
