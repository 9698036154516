import { cloneDeep } from "lodash-es";

const templateData = [
  {
    id: 90915,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '1',
    itemName: '生产环境条件（厂区、车间、设施、设备等）',
    reserved1: '1',
    children: 11,
    star: 'low',
  },
  {
    id: 90927,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '2',
    itemName: '进货查验',
    reserved1: '2',
    children: 3,
    star: 'low',
  },
  {
    id: 90931,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '3',
    itemName: '生产过程控制',
    reserved1: '3',
    children: 14,
    star: 'low',
    //status: 2,
    //zgcount: 1,
  },
  {
    id: 90946,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '4',
    itemName: '产品检验',
    reserved1: '4',
    children: 5,
    star: 'low',
  },
  {
    id: 90952,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '5',
    itemName: '贮存及交付控制',
    reserved1: '5',
    children: 6,
    star: 'low',
  },
  {
    id: 90959,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '6',
    itemName: '标签和说明书',
    reserved1: '6',
    children: 5,
    star: 'low',
  },
  {
    id: 90965,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '7',
    itemName: '其他主体责任',
    reserved1: '7',
    children: 2,
    star: 'low',
  },
  // {
  //   id: 90968,
  //   templateNo: '42852754ba064ff58d6425d33fa599aa',
  //   itemNo: '8',
  //   reserved1: '8',
  //   children: 0,
  // },
];
const templateChild = [
  {
    id: 90916,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '1.1',
    parentItem: '1',
    itemName: '厂区无扬尘、无积水，厂区、车间卫生整洁',
    optionId: '1,2,3',
    reserved1: '1.1',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90919,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '1.2',
    parentItem: '1',
    itemName: '厂区、车间与有毒、有害场所及其他污染源保持规定的距离或具备有效防范措施',
    optionId: '1,2,3',
    reserved1: '1.2',
    children: 0,
    star: 'hig',
    inputType: '1',
  },
  {
    id: 90920,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '1.3',
    parentItem: '1',
    itemName: '设备布局和工艺流程、主要生产设备设施与准予食品生产许可时保持一致',
    optionId: '1,2,3',
    reserved1: '1.3',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90921,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '1.4',
    parentItem: '1',
    itemName: '卫生间保持清洁，未与食品生产、包装或贮存等区域直接连通',
    optionId: '1,2,3',
    reserved1: '1.4',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90922,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '1.5',
    parentItem: '1',
    itemName: '有更衣、洗手、干手、消毒等卫生设备设施，满足正常使用',
    optionId: '1,2,3',
    reserved1: '1.5',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90923,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '1.6',
    parentItem: '1',
    itemName: '通风、防尘、排水、照明、温控等设备设施正常运行，存放垃圾、废弃物的设备设施标识清晰，有效防护',
    optionId: '1,2,3',
    reserved1: '1.6',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90924,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '1.7',
    parentItem: '1',
    itemName: '车间内使用的洗涤剂、消毒剂等化学品明显标示、分类贮存，与食品原料、半成品、成品、包装材料等分隔放置，并有相应的使用记录',
    optionId: '1,2,3',
    reserved1: '1.7',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90925,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '1.8',
    parentItem: '1',
    itemName: '生产设备设施定期维护保养，并有相应的记录',
    optionId: '1,2,3',
    reserved1: '1.8',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90926,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '1.9',
    parentItem: '1',
    itemName: '监控设备（如压力表、温度计）定期检定或校准、维护，并有相关记录',
    optionId: '1,2,3',
    reserved1: '1.9',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90917,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '1.10',
    parentItem: '1',
    itemName: '定期检查防鼠、防蝇、防虫害装置的使用情况并有相应检查记录，生产场所无虫害迹象',
    optionId: '1,2,3',
    reserved1: '1.10',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90918,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '1.11',
    parentItem: '1',
    itemName: '准清洁作业区、清洁作业区设置合理并有效分割。有空气净化要求的，应当符合相应要求，并对空气洁净度、压差、换气次数、温度、湿度等进行监测及记录。',
    optionId: '1,2,3',
    reserved1: '1.11',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90928,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '2.1',
    parentItem: '2',
    itemName: '查验食品原料、食品添加剂、食品相关产品供货者的许可证、产品合格证明文件等；供货者无法提供有效合格证明文件的，有检验记录。',
    optionId: '1,2,3',
    reserved1: '2.1',
    children: 0,
    star: 'hig',
    inputType: '1',
  },
  {
    id: 90929,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '2.2',
    parentItem: '2',
    itemName: '进货查验记录及证明材料真实、完整，记录和凭证保存期限符合要求',
    optionId: '1,2,3',
    reserved1: '2.2',
    children: 0,
    star: 'hig',
    inputType: '1',
  },
  {
    id: 90930,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '2.3',
    parentItem: '2',
    itemName: '建立和保存食品原料、食品添加剂、食品相关产品的贮存、保管记录、领用出库和退库记录',
    optionId: '1,2,3',
    reserved1: '2.3',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90932,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '3.1',
    parentItem: '3',
    itemName: '使用的食品原料、食品添加剂、食品相关产品的品种与索证索票、进货查验记录内容一致',
    optionId: '1,2,3',
    reserved1: '3.1',
    children: 0,
    star: 'hig',
    inputType: '1',
  },
  {
    id: 90938,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '3.2',
    parentItem: '3',
    itemName: '建立和保存生产投料记录，包括投料品名、生产日期或批号、使用数量等',
    optionId: '1,2,3',
    reserved1: '3.2',
    children: 0,
    star: 'hig',
    inputType: '1',
    status: 2,
  },
  {
    id: 90939,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '3.3',
    parentItem: '3',
    itemName: '未发现使用非食品原料、食品添加剂以外的化学物质、回收食品、超过保质期与不符合食品安全标准的食品原料和食品添加剂投入生产',
    optionId: '1,2,3',
    reserved1: '3.3',
    children: 0,
    star: 'hig',
    inputType: '1',
  },
  {
    id: 90940,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '3.4',
    parentItem: '3',
    itemName: '未发现超范围、超限量使用食品添加剂的情况',
    optionId: '1,2,3',
    reserved1: '3.4',
    children: 0,
    star: 'hig',
    inputType: '1',
  },
  {
    id: 90941,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '3.5',
    parentItem: '3',
    itemName: '生产或使用的新食品原料，限定于国务院卫生行政部门公吿的新食品原料范围内',
    optionId: '1,2,3',
    reserved1: '3.5',
    children: 0,
    star: 'hig',
    inputType: '1',
  },
  {
    id: 90942,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '3.6',
    parentItem: '3',
    itemName: '未发现使用药品生产食品，未发现仅用于保健食品的原料生产保健食品以外的食品',
    optionId: '1,2,3',
    reserved1: '3.6',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90943,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '3.7',
    parentItem: '3',
    itemName: '生产记录中的生产工艺和参数与准予食品生产许可时保持一致',
    optionId: '1,2,3',
    reserved1: '3.7',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90944,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '3.8',
    parentItem: '3',
    itemName: '建立和保存生产加工过程关键控制点的控制情况记录',
    optionId: '1,2,3',
    reserved1: '3.8',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90945,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '3.9',
    parentItem: '3',
    itemName: '生产现场未发现人流、物流交叉污染',
    optionId: '1,2,3',
    reserved1: '3.9',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90933,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '3.10',
    parentItem: '3',
    itemName: '未发现待加工食品与直接入口食品、原料与成品交叉污染',
    optionId: '1,2,3',
    reserved1: '3.10',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90934,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '3.11',
    parentItem: '3',
    itemName: '有温、湿度等生产环境监测要求的，定期进行监测并记录',
    optionId: '1,2,3',
    reserved1: '3.11',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90935,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '3.12',
    parentItem: '3',
    itemName: '工作人员穿戴工作衣帽，洗手消毒后进入生产车间。生产车间内未发现与生产无关的个人用品或者其他与生产不相关物品',
    optionId: '1,2,3',
    reserved1: '3.12',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90936,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '3.13',
    parentItem: '3',
    itemName: '食品生产加工用水的水质符合规定要求并有检测报告，与其他不与食品接触的用水以完全分离的管路输送',
    optionId: '1,2,3',
    reserved1: '3.13',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90937,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '3.14',
    parentItem: '3',
    itemName: '建立和保存不合格品的处置记录，不合格品的批次、数量应与记录一致',
    optionId: '1,2,3',
    reserved1: '3.14',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90947,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '4.1',
    parentItem: '4',
    itemName: '企业自检的，具备与所检项目适应的检验室和检验能力，有检验相关设备及化学试剂，检验仪器按期检定或校准',
    optionId: '1,2,3',
    reserved1: '4.1',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90948,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '4.2',
    parentItem: '4',
    itemName: '不能自检的，委托有资质的检验机构进行检验',
    optionId: '1,2,3',
    reserved1: '4.2',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90949,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '4.3',
    parentItem: '4',
    itemName: '有与生产产品相应的食品安全标准文本，按照食品安全标准规定进行检验',
    optionId: '1,2,3',
    reserved1: '4.3',
    children: 0,
    star: 'hig',
    inputType: '1',
  },
  {
    id: 90950,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '4.4',
    parentItem: '4',
    itemName: '建立和保存原始检验数据和检验报告记录，检验记录真实、完整，保存期限符合规定要求',
    optionId: '1,2,3',
    reserved1: '4.4',
    children: 0,
    star: 'hig',
    inputType: '1',
  },
  {
    id: 90951,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '4.5',
    parentItem: '4',
    itemName: '按规定时限保存检验留存样品并记录留样情况',
    optionId: '1,2',
    reserved1: '4.5',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90953,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '5.1',
    parentItem: '5',
    itemName: '食品原料、食品相关产品的贮存有专人管理，贮存条件符合要求',
    optionId: '1,2,3',
    reserved1: '5.1',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90954,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '5.2',
    parentItem: '5',
    itemName: '食品添加剂专库或专区贮存，明显标示，专人管理',
    optionId: '1,2,3',
    reserved1: '5.2',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90955,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '5.3',
    parentItem: '5',
    itemName: '不合格品在划定区域存放，具有明显标示',
    optionId: '1,2,3',
    reserved1: '5.3',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90956,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '5.4',
    parentItem: '5',
    itemName: '根据产品特点建立和执行相适应的贮存、运输及交付控制制度和记录',
    optionId: '1,2,3',
    reserved1: '5.4',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90957,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '5.5',
    parentItem: '5',
    itemName: '仓库温湿度符合要求',
    optionId: '1,2,3',
    reserved1: '5.5',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90958,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '5.6',
    parentItem: '5',
    itemName: '有出厂记录，如实记录食品的名称、规格、数量、生产日期或者生产批号、检验合格证明、销售日期以及购货者名称、地址、联系方式等内容',
    optionId: '1,2,3',
    reserved1: '5.6',
    children: 0,
    star: 'hig',
    inputType: '1',
  },
  {
    id: 90960,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '6.1',
    parentItem: '6',
    itemName: '预包装食品的包装有标签，标签标注的事项完整、真实',
    optionId: '1,2,3',
    reserved1: '6.1',
    children: 0,
    star: 'hig',
    inputType: '1',
  },
  {
    id: 90961,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '6.2',
    parentItem: '6',
    itemName: '未发现标注虚假生产日期或批号的情况',
    optionId: '1,2,3',
    reserved1: '6.2',
    children: 0,
    star: 'hig',
    inputType: '1',
  },
  {
    id: 90962,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '6.3',
    parentItem: '6',
    itemName: '未发现转基因食品、辐照食品未按规定标示',
    optionId: '1,2,3',
    reserved1: '6.3',
    children: 0,
    star: 'hig',
    inputType: '1',
  },
  {
    id: 90963,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '6.4',
    parentItem: '6',
    itemName: '食品添加剂标签载明“食品添加剂”字样，并标明贮存条件、生产者名称和地址、食品添加剂的使用范围、用量和使用方法',
    optionId: '1,2,3',
    reserved1: '6.4',
    children: 0,
    star: 'hig',
    inputType: '1',
  },
  {
    id: 90964,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '6.5',
    parentItem: '6',
    itemName: '未发现食品、食品添加剂的标签、说明书涉及疾病预防、治疗功能，未发现保健食品之外的食品标签、说明书涉及保健功能',
    optionId: '1,2,3',
    reserved1: '6.5',
    children: 0,
    star: 'hig',
    inputType: '1',
  },
  {
    id: 90966,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '7.1',
    parentItem: '7',
    itemName: '其他不符合《食品安全法》等相关法律、法规、规章、标准和技术规范相关要求',
    optionId: '1,2,3',
    reserved1: '7.1',
    children: 0,
    star: 'low',
    inputType: '1',
  },
  {
    id: 90967,
    templateNo: '42852754ba064ff58d6425d33fa599aa',
    itemNo: '7.2',
    parentItem: '7',
    itemName: '对前次检查发现的问题完成整改',
    optionId: '10',
    reserved1: '7.2',
    children: 0,
    star: 'low',
    inputType: '4',
    template: '对前次检查发现的问题完成整改:',
  },
];
export async function getMockTemplateData(templateNo) {
  const d = templateData.filter((item) => item.templateNo == templateNo);
  return cloneDeep(d)
}
export async function getMockTemplateDataChild(parentItem) {
  const d =  templateChild.filter((item) => item.parentItem == parentItem);
  return cloneDeep(d)
}
