<template>
  <Transfer
    :data-source="getdataSource"
    :filter-option="filterOption"
    :render="(item) => item.title"
    :showSelectAll="showSelectAll"
    :selectedKeys="selectedKeys"
    :targetKeys="getTargetKeys"
    :showSearch="showSearch"
    @change="handleChange"
  />
</template>

<script >
  import { computed, defineComponent, watch, ref, unref, watchEffect } from 'vue';
  import { Transfer } from 'ant-design-vue';
  import { isFunction } from '@/utils/is';
  import { get, omit } from 'lodash-es';
  import { propTypes } from '@/utils/propTypes';
  import { useI18n } from '@/hooks/web/useI18n';
  export default defineComponent({
    name: 'ApiTransfer',
    components: { Transfer },
    props: {
      value: { type: Array },
      api: {
        type: Function,
        default: null,
      },
      params: { type: Object },
      dataSource: { type: Array },
      immediate: propTypes.bool.def(true),
      alwaysLoad: propTypes.bool.def(false),
      afterFetch: { type: Function },
      resultField: propTypes.string.def(''),
      labelField: propTypes.string.def('title'),
      valueField: propTypes.string.def('key'),
      showSearch: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      filterOption: {
        type: Function,
      },
      selectedKeys: { type: Array },
      showSelectAll: { type: Boolean, default: false },
      targetKeys: { type: Array },
    },
    emits: ['options-change', 'change'],
    setup(props, { attrs, emit }) {
      const _dataSource = ref([]);
      const _targetKeys = ref([]);
      const { t } = useI18n();
      const getAttrs = computed(() => {
        return Object.assign(Object.assign({}, (!props.api ? { dataSource: unref(_dataSource) } : {})), attrs);
      });
      const getdataSource = computed(() => {
        const { labelField, valueField } = props;
        return unref(_dataSource).reduce((prev, next) => {
          if (next) {
            prev.push(Object.assign(Object.assign({}, omit(next, [labelField, valueField])), { title: next[labelField], key: next[valueField] }));
          }
          return prev;
        }, []);
      });
      const getTargetKeys = computed(() => {
        if (unref(_targetKeys).length > 0) {
          return unref(_targetKeys);
        }
        if (Array.isArray(props.value)) {
          return props.value;
        }
        return [];
      });
      function handleChange(keys, direction, moveKeys) {
        _targetKeys.value = keys;
        console.log(direction);
        console.log(moveKeys);
        emit('change', keys);
      }
      watchEffect(() => {
        props.immediate && !props.alwaysLoad && fetch();
      });
      watch(() => props.params, () => {
        fetch();
      }, { deep: true });
      async function fetch() {
        const api = props.api;
        if (!api || !isFunction(api)) {
          if (Array.isArray(props.dataSource)) {
            _dataSource.value = props.dataSource;
          }
          return;
        }
        _dataSource.value = [];
        try {
          const res = await api(props.params);
          if (Array.isArray(res)) {
            _dataSource.value = res;
            emitChange();
            return;
          }
          if (props.resultField) {
            _dataSource.value = get(res, props.resultField) || [];
          }
          emitChange();
        }
        catch (error) {
          console.warn(error);
        }
        finally {
        }
      }
      function emitChange() {
        emit('options-change', unref(getdataSource));
      }
      return { getTargetKeys, getdataSource, t, getAttrs, handleChange };
    },
  });

</script>
