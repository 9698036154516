<script>
  import { defineComponent, computed, unref, h } from 'vue';
  import { Tooltip } from 'ant-design-vue';
  import { InfoCircleOutlined } from '@ant-design/icons-vue';
  import { getPopupContainer } from '@/utils';
  import { isString, isArray } from '@/utils/is';
  import { getSlot } from '@/utils/helper/tsxHelper';
  import { useDesign } from '@/hooks/web/useDesign';

  const props = {
    /**
     * Help text max-width
     * @default: 600px
     */
    maxWidth: { type: String, default: '600px' },
    /**
     * Whether to display the serial number
     * @default: false
     */
    showIndex: { type: Boolean },
    /**
     * Help text font color
     * @default: #ffffff
     */
    color: { type: String, default: '#ffffff' },
    /**
     * Help text font size
     * @default: 14px
     */
    fontSize: { type: String, default: '14px' },
    /**
     * Help text list
     */
    placement: { type: String, default: 'right' },
    /**
     * Help text list
     */
    text: { type: [Array, String] },
  };

  export default defineComponent({
    name: 'BasicHelp',
    components: { Tooltip },
    props,
    setup(props, { slots }) {
      const { prefixCls } = useDesign('basic-help');

      const getTooltipStyle = computed(
        () => ({ color: props.color, fontSize: props.fontSize }),
      );

      const getOverlayStyle = computed(() => ({ maxWidth: props.maxWidth }));

      function renderTitle() {
        const textList = props.text;

        if (isString(textList)) {
          return h('p', undefined, textList)
        }

        if (isArray(textList)) {
          return textList.map((text, index) => {
            return h('p', {key: text}, [
                props.showIndex ? `${index + 1}. ` : '',
                text
            ])
          });
        }
        return null;
      }

      return () => {
        return h(Tooltip, {
                overlayClassName: `${prefixCls}__wrap`,
                title: h('div', { style:unref(getTooltipStyle) }, renderTitle()),
                autoAdjustOverflow: true,
                overlayStyle: unref(getOverlayStyle),
                placement: props.placement,
                getPopupContainer: () => getPopupContainer()
        }, h('span', { class: prefixCls }, getSlot(slots) || h('a-icon', {
        type: 'info-circle'
            })))

      };
    },
  });
</script>
<style lang="less">
  @prefix-cls: 'ant-basic-help';
  @text-color-help-dark: red;
  @primary-color: blue;

  .@{prefix-cls} {
    display: inline-block;
    margin-left: 6px;
    font-size: 14px;
    color: @text-color-help-dark;
    cursor: pointer;

    &:hover {
      color: @primary-color;
    }

    &__wrap {
      p {
        margin-bottom: 0;
      }
    }
  }
</style>
